import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash-es';
import { sortBy } from "../../common/constants";
const initialState = {
    createPost: false,
    showActivity: false,
    tagPost: false,
    postAudience: false,
    showPostThematic: false,
    articleCreation: false,
    uploadedFiles: [],
    selectedThematic: [],
    selectedActivity: "",
    selectedTags: [],
    indianCsrList: [],
    eventList: [],
    indianCsrPreview: [],
    myDatabase: [],
    category: null,
    postDashboard: [],
    postStatus: "Publish",
    selectedPostAudience: "Public",
    followersList: [],
    followingList: [],
    reactionList: [],
    comments: {},
    replies: {},
    downloadCertificate: '',
    connectionList: [],
    postAudienceEdit: false,
    postCategoryEdit: false,
    postActivityEdit: false,
    postTagPeopleEdit: false,
    selectedReactionTab: "all",
    shareData: [],
    chatMessages: [],
    eventCategory: null,
    eventSortBy: '',
    eventIsLoad: false,
    eventData: {},
    eventList: [],
    totalEventCount: 0,
    totalMyEventCount: 0,
    brandList: [],
    reactionCount: [],
    myEventList: [],
    eventStatus: "company_events",
    draftPost: [],
    postMode: "",
    activeChannel: "",
    users_list: [],
    totalPostCount: 0,
    notificationList: [],
    totalNotificationCount: 0,
    selectedConnTab: '',
    myInvitations: [],
    mySuggestions: [],
    recommendationList: [],
    totalRecommendationCount: 0,
    invitationList: [],
    totalInvitationCount: 0,
    connectionCount: [],
    connectionUserList: [],
    totalConnectionListCount: 0,
    volInvitationList: [],
    totalVolInvitationCount: 0,
    totalRequestedCount: 0,
    totalAcceptedCount: 0,
    speakerList: [],
    totalSpeakers: 0,
    sponsorList: [],
    totalSponsors: 0,
    socialRfpList: [],
    socialDashboardList: [],
    sortBy: null,
    glimpseFormData: {},
    selectedSampleQuestion: [],
    rfpDetailsFormData: {},
    rfpStatus: "",
    bEngageList: [],
    bEngangeIsLoad: false,
    engageTotalCount: 0,
    bengageDashboardList: [],
    isEventCardLoading: false,
    isConnectsCardLoading : false,
    isConnectionListLoading : false,
    isChatMessagesLoading : false,
    isPostLoading: false,
    prevAudience:'Public',
    bookmarkType:"",
    selectedSlug:"",
    notificationPostType:"",
    notificationPostGid:"",
    chatInitialFlag: false,
    myFeed:"",
    sharedList:[],
};

const SocialMediaSlice = createSlice({
    name: 'socialMedia',
    initialState,
    reducers: {
        resetSocialMediaSlice: () => initialState,
        setCreatePost(state, action) {
            state.createPost = action.payload
        },
        setShowActivity(state, action) {
            state.showActivity = action.payload
        },
        setTagPost(state, action) {
            state.tagPost = action.payload
        },
        setPostAudience(state, action) {
            state.postAudience = action.payload
        },
        setShowPostThematic(state, action) {
            state.showPostThematic = action.payload
        },
        setArticleCreation(state, action) {
            state.articleCreation = action.payload
        },
        setSelectedActivity(state, action) {
            state.selectedActivity = action.payload
        },
        setSelectedThematic(state, action) {
            state.selectedThematic = action.payload
        },
        setEventList(state, action) {
            state.eventList = action.payload
        },
        setPostDashboard(state, action) {
            state.postDashboard = action.payload
        },
        setMyDatabase(state, action) {
            state.myDatabase = action.payload
        },
        setIndianCsrList(state, action) {
            state.indianCsrList = action.payload
        },
        setIndianCsrPreview(state, action) {
            state.indianCsrPreview = action.payload
        },
        setUploadedFiles: (state, action) => {
            state.uploadedFiles = action.payload;
        },
        setSelectedTags: (state, action) => {
            state.selectedTags = action.payload;
        },
        addUploadedFile: (state, action) => {
            state.uploadedFiles.push(action.payload);
        },
        removeUploadedFile: (state, action) => {
            state.uploadedFiles = state.uploadedFiles.filter((file, index) => index !== action.payload);
        },
        clearUploadedFiles(state) {
            state.uploadedFiles = [];
        },
        setCategory(state, action) {
            state.category = action.payload;
        },
        setSelectedPostAudience(state, action) {
            state.selectedPostAudience = action.payload;
        },
        setPostStatus(state, action) {
            state.postStatus = action.payload;
        },
        setFollowersList(state, action) {
            state.followersList = action.payload;
        },
        setFollowingList(state, action) {
            state.followingList = action.payload;
        },
        setPostAudienceEdit(state, action) {
            state.postAudienceEdit = action.payload;
        },
        setSelectedReactionTab(state, action) {
            state.selectedReactionTab = action.payload;
        },
        setShareData(state, action) {
            state.shareData = action.payload;
        },
        setDraftPost(state, action) {
            state.draftPost = action.payload;
        },
        setPostCategoryEdit(state, action) {
            state.postCategoryEdit = action.payload;
        },
        setReactionCount(state, action) {
            state.reactionCount = action.payload;
        },
        setPostActivityEdit(state, action) {
            state.postActivityEdit = action.payload;
        },
        setPostTagPeopleEdit(state, action) {
            state.postTagPeopleEdit = action.payload;
        },
        setPostComment(state, action) {
            // if(_.isArray(action.payload)){
            //     state.comment = action.payload
            // }else{
            //     state.comment.push(action.payload)
            // }
            const { postId, id, data, editFlag } = action.payload;
            if (editFlag) {
                state.comments = {
                    ...state.comments,
                    [postId]: state?.comments[postId]?.map(comment =>
                        comment.id === id ? { ...comment, body: data?.body } : comment
                    )
                }
            } else {
                state.comments = {
                    ...state.comments,
                    [postId]: [...data]
                }
            }
        },
        addPostComment(state, action) {
            const { postId, data } = action.payload;
            if (Object.keys(state.comments).length !== 0) {
                state.comments = {
                    ...state.comments,
                    [postId]: [data, ...state.comments[postId]]
                }
            } else {
                state.comments = {
                    [postId]: [data]
                }
            }
        },
        updatePostComment(state, action) {
            const { postId, id } = action.payload;

            if (state.comments[postId]) {
                state.comments = {
                    ...state.comments,
                    [postId]: state.comments[postId].filter(comment => comment.gid !== id)
                };
            }
        },
        setPostReply(state, action) {
            // if(_.isArray(action.payload)){
            //     state.comment = action.payload
            // }else{
            //     state.comment.push(action.payload)
            // }
            const { commentId, id, data, editFlag } = action.payload;
            if (editFlag) {
                state.replies = {
                    ...state.replies,
                    [commentId]: state?.replies[commentId]?.map(reply =>
                        reply?.gid === id ? { ...reply, body: data.body } : reply
                    )
                }
            } else {
                state.replies = {
                    ...state?.replies,
                    [commentId]: [...data]
                }
            }
        },
        addPostReply(state, action) {
            const { commentId, data } = action.payload;
            if (Object.keys(state.replies).length !== 0) {
                state.replies = {
                    ...state?.replies,
                    [commentId]: [...state?.replies[commentId], data]
                }
            } else {
                state.replies = {
                    [commentId]: [data]
                }
            }
        },
        updatePostReplies(state, action) {
            const { commentId, id } = action.payload;
    
            if (state.replies[commentId]) {
                state.replies = {
                    ...state.replies,
                    [commentId]: state.replies[commentId].filter(reply => reply.gid !== id)
                };
            }
        },
        setConnectionList(state, action) {
            state.connectionList = action.payload;
        },
        setChatMessages(state, action) {
            state.chatMessages = action.payload;
        },
        setActiveChannel(state, action) {
            state.activeChannel = action.payload;
        },
        setReactionList(state, action) {
            state.reactionList = action.payload;
        },
        setEventCategory(state, action) {
            state.eventCategory = action.payload
        },
        setEventSortBy(state, action) {
            state.eventSortBy = action.payload
        },
        addEventData(state, action) {
            state.eventData = action.payload;
        },
        setEventListData(state, action) {
            state.eventList = action.payload;
        },
        setBrandListData(state, action) {
            state.brandList = action.payload;
        },
        setTotalCount(state, action) {
            state.totalEventCount = action.payload;
        },
        setTotalMyCount(state, action) {
            state.totalMyEventCount = action.payload;
        },
        setEventIsLoad(state, action) {
            state.eventIsLoad = action.payload;
        },
        setEventStatus(state, action) {
            state.eventStatus = action.payload;
        },
        setMyEventListData(state, action) {
            state.myEventList = action.payload;
        },
        setPostMode(state, action) {
            state.postMode = action.payload;
        },
        resetDraftAndActivity(state) {
            state.draftPost = [];
            state.selectedActivity = "";
            state.selectedThematic = []
        },
        setDownloadCertificate(state, action) {
            state.downloadCertificate = action.payload;
        },
        setUserList(state, action) {
            state.users_list = action.payload;
        },
        setTotalPostCount(state, action) {
            state.totalPostCount = action.payload;
        },
        setNotificationList(state, action) {
            state.notificationList = action.payload;
        },
        setTotalNotificationCount(state, action) {
            state.totalNotificationCount = action.payload;
        },
        setSelectedConnTab(state, action) {
            state.selectedConnTab = action.payload;
        },
        setMyInvitations(state, action) {
            state.myInvitations = action.payload;
        },
        setMySuggestions(state, action) {
            state.mySuggestions = action.payload;
        },
        setRecommendations(state, action) {
            state.recommendationList = action.payload;
        },
        setTotalRecommendations(state, action) {
            state.totalRecommendationCount = action.payload;
        },
        setInvitations(state, action) {
            state.invitationList = action.payload;
        },
        setTotalInvitations(state, action) {
            state.totalInvitationCount = action.payload;
        },
        setConnectionCount(state, action) {
            state.connectionCount = action.payload;
        },
        setConnectionUserList(state, action) {
            state.connectionUserList = action.payload;
        },
        setTotalConnectionListCount(state, action) {
            state.totalConnectionListCount = action.payload;
        },
        setVolInvitationList(state, action) {
            state.volInvitationList = action.payload;
        },
        setSpeakerList(state, action) {
            state.speakerList = action.payload;
        },
        setSponsorList(state, action) {
            state.sponsorList = action.payload;
        },
        setTotalVolInvitationCount(state, action) {
            state.totalVolInvitationCount = action.payload;
        },
        setTotalRequestedCount(state, action) {
            state.totalRequestedCount = action.payload;
        },
        setTotalAcceptedCount(state, action) {
            state.totalAcceptedCount = action.payload;
        },
        setTotalSpeakerCount(state, action) {
            state.totalSpeakers = action.payload;
        },
        setTotalSponsorCount(state, action) {
            state.totalSponsors = action.payload;
        },
        setSocialRfpList(state, action) {
            state.socialRfpList = action.payload;
        },
        setSocialDashboardList(state, action) {
            state.socialDashboardList = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setGlimpseFormData(state, action) {
            state.glimpseFormData = action.payload;
        },
        setSelectedSampleQuestion(state, action) {
            state.selectedSampleQuestion = action.payload;
        },
        setDetails(state, action) {
            state.rfpDetailsFormData = action.payload;
        },
        setRfpStatus(state, action) {
            state.rfpStatus = action.payload;
        },
        setBEngageList(state, action) {
            state.bEngageList = action.payload;
        },
        setBEngangeIsLoad(state, action) {
            state.bEngangeIsLoad = action.payload;
        },
        setEngageTotalCount(state, action) {
            state.engageTotalCount = action.payload;
        },
        setBengageDashboardList(state, action) {
            state.bengageDashboardList = action.payload;
        },

        setIsEventCardLoading(state, action) {
            state.isEventCardLoading = action.payload;
        },
        setIsConnectsCardLoading(state, action) {
            state.isConnectsCardLoading = action.payload;
        },
        resetPostAudience(state) {
            state.postAudience = initialState.postAudience;
        },
        resetPostTags(state) {
            state.selectedTags = initialState.selectedTags;
        },
        resetShowPostThematics(state) {
            state.showPostThematic = initialState.showPostThematic;
        },
        setIsConnectionListLoading(state, action) {
            state.isConnectionListLoading = action.payload;
        },
        setIsChatMessagesLoading(state, action) {
            state.isChatMessagesLoading = action.payload;
        },
        setPrevAudience(state, action) {
            state.prevAudience = action.payload;
        },
        resetPrevAud(state) {
            state.prevAudience = initialState.prevAudience;
        },
        resetPostEdit(state) {
            state.postCategoryEdit = initialState.postCategoryEdit;
        },
        resetSelectedPostAudience(state){
            state.selectedPostAudience = initialState.selectedPostAudience;
        },
        setBookmarkType(state, action) {
            state.bookmarkType = action.payload;
        }, 
        setIsPostLoading(state, action) {
            state.isPostLoading = action.payload;
        },
        setSelectedSlug(state, action) {
            state.selectedSlug = action.payload;
        }, 
        resetSelectedSlug(state) {
            state.selectedSlug = initialState.selectedSlug;
        },
        setNotificationPostType(state, action) {
            state.notificationPostType = action.payload;
        }, 
        setNotificationPostGid(state, action) {
            state.notificationPostGid = action.payload;
        }, 
        resetNotificationPostType(state) {
            state.notificationPostType = initialState.notificationPostType;
        },
        chatMessagesInitialFlag(state, action) {
            state.chatInitialFlag = action.payload;
        },
        setMyFeed(state, action) {
            state.myFeed = action.payload;
        },
        resetBookmarkType(state) {
            state.bookmarkType = initialState.bookmarkType;
        },
        setSharedList(state,action){
            state.sharedList = action.payload;
        },
        resetPostDashboard(state,action){
            state.indianCsrPreview = initialState.indianCsrPreview;
        },
        resetSelectedThemetic(state){
            state.selectedThematic = initialState.selectedThematic;
        },
        resetSelectedActivity(state){
            state.selectedActivity = initialState.selectedActivity;
        }
    }
});
export default SocialMediaSlice.reducer;
export { initialState };
export const {
    resetSocialMediaSlice,
    chatMessagesInitialFlag,
    setActiveChannel,
    setChatMessages,
    setNotificationList,
    setTotalNotificationCount,
    setSelectedConnTab,
    setMyInvitations,
    setMySuggestions,
    setRecommendations,
    setTotalRecommendations,
    setInvitations,
    setTotalInvitations,
    setConnectionCount,
    setConnectionUserList,
    setTotalConnectionListCount,
    setVolInvitationList,
    setTotalVolInvitationCount,
    setTotalRequestedCount,
    setTotalAcceptedCount,
    setSpeakerList,
    setSponsorList,
    setTotalSpeakerCount,
    setTotalSponsorCount,
    setDownloadCertificate,
    setUserList,
    setPostComment,
    addPostComment,
    setPostReply,
    addPostReply,
    setEventCategory,
    setEventSortBy,
    setBrandListData,
    setEventIsLoad,
    setEventListData,
    setTotalCount,
    setEventStatus,
    setMyEventListData,
    addEventData,
    setTotalMyCount,
    updatePostComment,
    updatePostReplies,
    setReactionList,
    setCreatePost,
    setShowActivity,
    setTagPost,
    setPostAudience,
    setShowPostThematic,
    setArticleCreation,
    setSelectedActivity,
    setUploadedFiles,
    addUploadedFile,
    removeUploadedFile,
    setSelectedThematic,
    setIndianCsrList,
    clearUploadedFiles,
    setSelectedTags,
    setEventList,
    setIndianCsrPreview,
    setCategory,
    setMyDatabase,
    setPostDashboard,
    setPostStatus,
    setSelectedPostAudience,
    setFollowersList,
    setFollowingList,
    setReactionCount,
    setConnectionList,
    setPostAudienceEdit,
    setPostCategoryEdit,
    setPostActivityEdit,
    setPostTagPeopleEdit,
    setSelectedReactionTab,
    setShareData,
    setDraftPost,
    setPostMode,
    resetDraftAndActivity,
    setTotalPostCount,
    setSocialRfpList,
    setSocialDashboardList,
    setSortBy,
    setGlimpseFormData,
    setSelectedSampleQuestion,
    setDetails,
    setRfpStatus,
    setBEngageList,
    setBEngangeIsLoad,
    setEngageTotalCount,
    setBengageDashboardList,
    setIsEventCardLoading,
    setIsConnectsCardLoading,
    resetPostAudience,
    resetPostTags,
    setIsConnectionListLoading,
    setIsChatMessagesLoading,
    resetShowPostThematics,
    setPrevAudience,
    resetPrevAud,
    resetPostEdit,
    setBookmarkType,
    setIsPostLoading,
    setSelectedSlug,
    resetSelectedSlug,
    setNotificationPostType,
    setNotificationPostGid,
    resetNotificationPostType,
    resetBookmarkType,
    setMyFeed,
    resetSelectedPostAudience,
    setSharedList,
    resetPostDashboard,
    resetSelectedThemetic,
    resetSelectedActivity,
} = SocialMediaSlice.actions;