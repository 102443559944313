// <-<-<-<-Image Cropper->->->->

import EditIcon from '../../assets/icons/EditBlue.svg';
import uploadIcon from '../../assets/icons/upload_camera.svg';
import userProfile from '../../assets/images/user_profile.png';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { uploadProfile } from '../../modules/Profile/actions';
import { setSnackbar } from '../../store/slices/MessageSlice';
import { bytesToKb, fileToBase64 } from '../utils';
import CommonInitials from './CommonInitials';
import ProfileCss from '../../modules/Profile/NewProfile/Common/ProfileCss/Profile.module.css'
import { setIsUploaded } from '../../store/slices/UserSlice';

const RenderImageUploader = ({
  input,
  label,
  style,
  sizeLimit,
  uploadFormat,
  uploadInfo,
  loadImage,
  className,
  showEdit,
  showUpload,
  meta: { touched, error },
  ...props
}) => {
  const [image, setImage] = useState(null);
  const [imageTouched, setImageTouched] = useState(true);
  const [uploadError, setUploadError] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const cropperRef = useRef(null);
  // useEffect(() => {
  //   setImageTouched(touched);
  // }, [touched]);

  useEffect(() => {
    if (loadImage.image != null) {
      setImage(loadImage.image);
    }
  }, [loadImage]);

  useEffect(() => {
    setUploadError(error);
    if (error) setImage(undefined);
  }, [error]);

  const dataUrlToFile = (dataUrl, filename) => {
    return fetch(dataUrl)
      .then((response) => response.blob())
      .then((blob) => new File([blob], filename));
  };
  const handleCrop = () => {
    if (cropper) {
      const croppedImageUpload = cropper.getCroppedCanvas().toDataURL('image/jpeg', 'image.jpg', 'image/png', 0.8);

      setCroppedImage(croppedImageUpload);
      if(showUpload === 'true'){
        // Convert data URL to file
        // dataUrlToFile(croppedImageUpload, 'cropped_image.png').then((file) => {
          dispatch(setIsUploaded(croppedImageUpload));
          setDialogOpen(false);
        // });
      }else{
        // Convert data URL to file
        dataUrlToFile(croppedImageUpload, `file_${Math.random().toString(36).substring(2, 15)}.png`).then((file) => {
          dispatch(uploadProfile(file, input.name));
          setDialogOpen(false);
        });
      }
    }
  };

  const handleOpenDialog = () => {
    // Clear the input value to ensure onChange is triggered
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
    fileInputRef.current.click();
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': uploadFormat,
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        dispatch(setSnackbar({ flag: true, message: 'Invalid file format. Please upload a valid file.', type: 'error' }));
        return;
      }
      const uploadedImage = acceptedFiles[0];
      fileToBase64(uploadedImage, (base64String) => {
        setImage(base64String);
        input.onChange(image);
      });
      setImageTouched(true);
      if (bytesToKb(uploadedImage.size) <= sizeLimit) {
        setDialogOpen(true);
      } else {
        dispatch(setSnackbar({ flag: true, message: 'File size exceeds the maximum limit of 2MB.', type: "error" }));
        setDialogOpen(false);
      }
    },
  });
  return (
    <>
      <Box {...getRootProps()}>
        {(croppedImage || loadImage.image) ? (
          <Box className={ProfileCss.profileImgContainer} onMouseEnter={() => setImageTouched(true)} onMouseLeave={() => setImageTouched(true)} onClick={(e) => e.stopPropagation()}
          >
            <img
              src={croppedImage || loadImage.image}
              alt=""
              style={{ objectFit: 'contain', overflow:'hidden', borderRadius:'50%', ...style }}
              className={className}
              onClick={(e) => e.stopPropagation()}
              loading="lazy"
            />
            {image && imageTouched &&  (
              <>
              {showEdit=== 'true' &&
              <>
                <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
                <Box className={ProfileCss.editDPicon} onClick={handleOpenDialog} >
                  <img src={EditIcon} height={'24px'} width={'24px'}/>
                </Box></>}
                {showUpload=== 'true' &&
              <>
                <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
                <Box className={ProfileCss.uploadicon} onClick={handleOpenDialog} >
                  <img src={uploadIcon} height={'48px'} width={'48px'}/>
                  <Typography className={ProfileCss.uploadTypo}>Upload</Typography>
                </Box></>}
              </>
            )}
          </Box>
        ) : (
            <Box className={ProfileCss.profileImgContainer} onMouseEnter={() => setImageTouched(true)} onMouseLeave={() => setImageTouched(true)} onClick={(e) => e.stopPropagation()}
            >
              {showUpload === "true" ? <img
              src={userProfile}
              alt=""
              style={{ objectFit: 'contain', overflow:'hidden', borderRadius:'50%', ...style }}
              className={className}
              onClick={(e) => e.stopPropagation()}
              loading="lazy"
            /> :
              <CommonInitials name={props?.initials} className={className} divStyle={{
                ...style, overflow:'hidden', borderRadius:'50%'
              }} typoStyle={{ fontSize: '36px', lineHeight: '100px' }} bgColor={props?.colorCode}/>}
              {imageTouched && (
                <>
                {showEdit=== 'true' &&
              <>
                  <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
                  <Box className={ProfileCss.editDPicon} onClick={handleOpenDialog} >
                    <img src={EditIcon} height={'24px'} width={'24px'}/>
                  </Box>
                  </>}
                  {showUpload=== 'true' &&
              <>
                <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
                <Box className={ProfileCss.uploadicon} onClick={handleOpenDialog} >
                  <img src={uploadIcon} height={'48px'} width={'48px'}/>
                  <Typography className={ProfileCss.uploadTypo}>Upload</Typography>
                </Box></>}
                </>
              )}
            </Box>
        )}
        {imageTouched && uploadError && !loadImage.image && <div className="error">{error}</div>}
      </Box>
      <div>
        <Dialog maxWidth='sm' open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogContent>
            {image && (
              <Cropper
                ref={cropperRef}
                src={image || loadImage.image}
                style={{ height: 400, width: '100%' }}
                aspectRatio={1}
                // zoomTo={0.4}
                preview=".img-preview"
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                guides={true}
                checkOrientation={false} 
                onInitialized={(instance) => {
                  setCropper(instance);
                  input.onChange(image);
                }} 
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCrop} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default RenderImageUploader;