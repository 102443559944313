import { Box, Divider, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { styled } from '@mui/styles';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useDispatch } from 'react-redux';
import CommonInitials from '../../../../common/components/CommonInitials';
import { useNavigate } from 'react-router';
import slugify from 'slugify';

const SharedPostHeader = ({ post, users, master, socialMedia, isLoggedIn }) => {
    const navigate = useNavigate();
    const handleUserProfile = (e) => {
        e.stopPropagation();
        if (users?.isLoggedIn) {
            navigate(`/profile/${post?.shared_module?.user_gid}`);
        } else {
            navigate('/login');
        }
    }
    const handleConferenceClick = (post, e) => {
        e.stopPropagation();
        if (isLoggedIn) {
            if (post?.shareable && post?.shared_module?.conferenec_gid) {
                const slug = slugify(post?.event_name, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                const slugUrl = `${slug}-${post?.conferenec_gid}`;
                navigate(`/volunteer/${slugUrl}`);
            } else if (post?.shareable && post?.shared_module?.event_gid) {
                navigate(`/social-media/events/${post?.shared_module?.event_gid}`)
            } else if (post?.shared_module?.rfp_title) {
                navigate(`/social-media/rfp/${post?.shared_module?.rfp_gid}`)
            } else if (post?.multiple_shared_post || post?.shareable) {
                if (post?.post_gid) {
                    navigate(`/social-media/posts/${post?.post_gid}`)
                }
            }
        }
    }
    const handleParentPost = (e) => {

        if (isLoggedIn) {
            if (post?.multiple_shared_post || post?.shareable) {
                if (post?.post_gid) {
                    navigate(`/social-media/posts/${post?.post_gid}`)
                }
            }
        } else {
            navigate('/login');
        }
    }
    return (
        <>
            <Box className={`${SocialMediaCSS.postHeader} ${((post?.shareable && (post?.shared_module?.conferenec_gid || post?.shared_module?.event_gid)) || (post?.multiple_shared_post || post?.shareable)) ? SocialMediaCSS.cursor : ''}`} onClick={(e) => handleConferenceClick(post, e)} >
                <Box className={SocialMediaCSS.cursor} onClick={(e) => handleUserProfile(e)}>
                    <CommonInitials
                        name={post?.shared_module?.initials}
                        bgColor={post?.shared_module?.colour_code}
                        divStyle={{ padding: '0px', maxHeight: '130px', maxWidth: '130px', minHeight: '34px', minWidth: '34px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                        typoStyle={{ fontSize: '14px', lineHeight: '32px', }}
                        avatarStyle={{ width: '34px', height: '34px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                        src={post?.shared_module?.image}
                        companyLogo={post?.shared_module?.image}
                        avatar={true}
                    />
                </Box>
                <Box className={SocialMediaCSS.postHeaderInfo} onClick={(e) => handleConferenceClick(post, e)}>
                    <Typography className={`${SocialMediaCSS.postReshareHeader} ${SocialMediaCSS.cursor}`} sx={{ display: 'inline'}} onClick={(e) =>  { e.stopPropagation();  handleUserProfile(e)}} >
                        {post?.shared_module?.name}
                    </Typography>
                    <Typography className={SocialMediaCSS.postReshareTagline} >
                        {post?.shared_module?.tagline}
                    </Typography>
                </Box>

            </Box>
            <Divider />
        </>
    )
}

export default SharedPostHeader
