import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import SocialMediaCSS from "../../SocialMediaCSS/SocialMedia.module.css";
import PostHeader from "./PostHeader";
import PostMedia from "./PostMedia";
import PostContent from "./PostContent";
import PostActions from "./PostActions";
import PostComments from "./PostComments";
import EventPostContent from "./EventPostContent";
import { useDispatch } from "react-redux";
import SharedPostHeader from "./SharedPostHeader.jsx";

const SinglePostLayout = ({ socialMedia, post, master, users, form, offset }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const is_Share = post?.shared_module?.name === null ? false : true;

  return (
    <>
      <Box className={SocialMediaCSS.postCard}>
        <PostHeader post={post} users={users} master={master} socialMedia={socialMedia} />
        <Divider sx={{ marginTop: '-20px' }} />
        {is_Share === false ? (
          <>
            <PostMedia post={post} />
            <PostContent post={post?.body} sharedRFP={post} isLoggedIn={users?.isLoggedIn} />
          </>
        ) : (
          <>
            <PostContent post={post?.body} isLoggedIn={users?.isLoggedIn} />
            <Box className={SocialMediaCSS.postCard}>
              {!post?.shared_module?.certificate_image &&
                <SharedPostHeader post={post} users={users} master={master} socialMedia={socialMedia} isLoggedIn={users?.isLoggedIn} />}
              <PostMedia post={post} />
              <PostContent post={post?.shared_module?.body} sharedRFP={post} isLoggedIn={users?.isLoggedIn} />
            </Box>
          </>
        )}

        {/* Incase of Event */}
        {/* <EventPostContent /> */}
        {!post?.multiple_shared_post &&
        <>
        <Divider />
        <PostActions post={post} master={master} users={users} form={form} />
        </>}
        {/* Comment section Show and hide condition will be added here */}
        {/* <PostComments /> */}
      </Box>
    </>
  )
}

export default SinglePostLayout
