import { reset } from 'redux-form'
import _ from 'lodash-es';
import { fetchAPI, getMasterData, getNotificationCount, resetAllForms, } from '../../../../common/actions';
import { httpMethod, COMMON_MSG, STATUS_CODE, EVENT_DASHBOARD, PAGE_LIMIT } from '../../../../common/constants';
import {
    addEventMode,
    addEventType,
    addEventArea,
    addEventBeneficiaryTypes,
    addTransportaionMode,
    addManagementType,
    addLanguage,
    setIsLoad,
} from '../../../../store/slices/MasterSlice';
import { setSnackbar } from '../../../../store/slices/MessageSlice';
import { base64ToFile, isEmpty, } from '../../../../common/utils';
import {
    EVENT_MODE,
    EVENT_TYPE,
    EVENT_AREA,
    CREATE_EVENT,
    UPDATE_EVENT,
    EVENT_BENEFICIARY,
    EVENT_TRANSPORTATION_MODE,
    LANGUAGE,
    MANAGEMENT_TYPE,
    EVENT_MARK_FAVOURITE,
    EVENT_PREVIEW,
    EVENT_LIST,
    BRAND_LIST,
    CANCEL_EVENT,
    SOCIAL_OUTCOME,
    GET_VOLUNTEERS,
    ADD_VOLUNTEER,
    DELETE_VOLUNTEER,
    JOIN_EVENT,
    OPT_OUT_EVENT,
    INSIGHT,
    RESEND_VOLUNTEER_EMAIL,
    UPLOAD_CSV,
    VOLUNTEER_COUNT,
    USER_DETAIL,
    VOLUNTEER_ACCEPT_DENY,
    CERTIFICATE,
    CERTIFICATE_POST,
    DOWNLOAD_CERTIFICATE,
    WITHDRAW_INVITE,
    RESET_NOTIFICATION,
    EVENT_LEADERBOARD,
    CANCEL_EVENT_FEEDBACK,
    CANCEL_EVENT_FEEDBACK_LIST,
    APPROVAL_REQUEST,
    SOCIAL_EVENT_LIST,
    EVENT_LIKE,
    EVENT_DELETE_LIKE,
    EVENT_BOOKMARK,
    EVENT_DELETE_BOOKMARK,
    SHARE_RATING,
    SOCIAL_EVENT_PREVIEW,
} from '../../../../http/apiUrls';
import {
    addEventDistrict,
    addEventUploadedData,
    setUniqueId,
    setEventInsightData,
    setBrandListData,
    updateEventUploadedData,
    addOutcomeData,
    updateAllVolunteers,
    setPrivateEvent,
    setVolunteersCount,
    setAddPopupErrorData,
    setTileData,
    setCertificate,
    setTotalVolunteersCount,
    setLeaderboardList,
    setCancelledFeedbacksList,
    addPOC,
    updatePOC,
    setNewPocFlag,
    setUnqId,
    setEventScheduleDates,
    setEventScheduleTime,
    setSelectedEventMode,
    setCertificate_selected,
    setEventInitalValues,
    setSelectedEventType,
    setEvent_Status_code,
    setPublishMessage
} from '../../../../store/slices/EventSlice';
import { addEventData, setDownloadCertificate, setEventIsLoad, setEventListData, setMyEventListData, setTotalCount, setTotalMyCount } from '../../../../store/slices/SocialMediaSlice';


export const submitEvent = (values, navigate) => (dispatch, getState) => {
    dispatch(publishEvent(navigate));
}

export const getEventUniqueId = () => (dispatch, getState) => {
    const { events: { uniqueId } } = getState();
    return uniqueId;
}

export const fetchMasterData = () => (dispatch) => {
    dispatch(getMasterData());
}
export const getEventMode = () => (dispatch) => {
    dispatch(fetchAPI(EVENT_MODE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addEventMode(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getEventBeneficiary = () => (dispatch) => {
    dispatch(fetchAPI(EVENT_BENEFICIARY, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addEventBeneficiaryTypes(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getEventTransportationMode = () => (dispatch) => {
    dispatch(fetchAPI(EVENT_TRANSPORTATION_MODE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addTransportaionMode(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getLanguage = () => (dispatch) => {
    dispatch(fetchAPI(LANGUAGE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addLanguage(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getManagementType = () => (dispatch) => {
    dispatch(fetchAPI(MANAGEMENT_TYPE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addManagementType(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getEventArea = () => (dispatch) => {
    dispatch(fetchAPI(EVENT_AREA, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addEventArea(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getEventType = () => (dispatch) => {
    dispatch(fetchAPI(EVENT_TYPE, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addEventType(response.data))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}

export const getEventDistrict = (state) => (dispatch, getState) => {
    const { master: { district } } = getState();
    const newArr = district.filter((data) => data.state_id === parseInt(state));
    dispatch(addEventDistrict(newArr));
}

export const uploadEvents = (file, uploadInfo) => (dispatch, getState) => {
    const { events } = getState();
    let dataToPost = {
        filename: uploadInfo.FIELD_NAME,
        key: uploadInfo.KEY,
        file,
    }
    if (!isEmpty(events.eventData) && events.eventData.uniqueId) { // for update
        dataToPost = {
            ...dataToPost,
            uniqueId: events.eventData.uniqueId
        }
    }
    const uploadedData = {
        // ...dataToPost,
        filename: file.name,
        key: uploadInfo.FIELD_NAME,
    }
    dispatch(addEventUploadedData(uploadedData, uploadInfo.FIELD_NAME));
    return uploadedData;
}

export const getEventTypeText = (id) => (dispatch, getState) => {
    const { master: { eventType } } = getState();
    const searchData = eventType.find((state) => state.id === parseInt(id));
    if (searchData) {
        return searchData.name;
    }
};

export const getEventModeText = (id) => (dispatch, getState) => {
    const { master: { eventMode } } = getState();
    const searchData = eventMode.find((state) => state.id === parseInt(id));
    if (searchData) {
        return searchData.name;
    }
};

export const getEventAreaText = (id) => (dispatch, getState) => {
    const { master: { eventArea } } = getState();
    const searchData = eventArea.find((state) => state.id === parseInt(id));
    if (searchData) {
        return searchData.name;
    }
};

export const deleteEventUploadedFile = (uploadInfo) => (dispatch, getState) => {
    const { events } = getState();
    let eventData = {
        ...events.eventData,
        cover_photo: "",
    }
    dispatch(addEventData(eventData));
    dispatch(removeUploadedData(uploadInfo.FIELD_NAME));
}

export const removeUploadedData = (key) => (dispatch, getState) => {
    const { events: { uploadedData, } } = getState();
    const newArr = uploadedData.filter((data) => (
        data.key !== key
    ));
    dispatch(updateEventUploadedData(newArr));
}


const isDataURL = (str) => {
    return str.startsWith('data:image/') && str.includes(';base64,');
}
export const publishEvent = (navigate = '') => (dispatch, getState) => {
    const { events, users, form } = getState();
    let data = events?.eventData;
    let APIUrl = events?.unqId || data?.unique_id ? `${UPDATE_EVENT}${events?.unqId || data?.unique_id}` : CREATE_EVENT;
    let method = events?.unqId || data?.unique_id ? httpMethod.PUT : httpMethod.POST;
    if (_.isEmpty(data?.cover_photo)) {
        dispatch(setSnackbar({ flag: true, message: COMMON_MSG.EVENT_COVER_PHOTO_ERROR, type: "error" }));
        return;
    }
    let Role_Id = users.userData?.data?.role_id;
    let company_id;
    if ((data?.management_type_id === 1 || data?.management_type_id === 2) || !('management_type_id' in data)) {
        company_id = users?.userData?.data?.admin_company_id;
    } else { // managed for id
        company_id = events.brandList.find(item => item.id === data?.partner_id)?.company_id;
    }
    let user_id = users?.userData?.data?.id;
    let company_Id = users?.userData?.data?.company_id;
    let co_owner_id = data?.management_type_id === 1 ? null : data?.management_type_id === 2 ? events.brandList.find(item => item.id === data?.partner_id)?.company_id : data?.management_type_id === 3 ? users?.userData?.data?.admin_company_id : null;
    let newData = {
        ...data,
        // status_code: Role_Id === 1 ?
        //     (events?.eventPublish !== "draft" ?
        //         STATUS_CODE.PUBLISH.CODE : STATUS_CODE.DRAFT.CODE) :
        //     (Role_Id === 2 && events?.eventPublish !== "draft" && events?.event_Approval === "no" ?
        //         STATUS_CODE.PUBLISH.CODE : STATUS_CODE.DRAFT.CODE),
        status_code: data.management_type_id === 1 ?
        (Role_Id === 1 ?
            (events?.eventPublish !== "draft" ?
                STATUS_CODE.PUBLISH.CODE : STATUS_CODE.DRAFT.CODE) :
            (Role_Id === 2 && events?.eventPublish !== "draft" && events?.event_Approval === "no" ?
                STATUS_CODE.PUBLISH.CODE : STATUS_CODE.DRAFT.CODE)) :
        STATUS_CODE.DRAFT.CODE,
        // status_code: events?.secondaryTab !== "draft" ? STATUS_CODE.PUBLISH.CODE : STATUS_CODE.DRAFT.CODE,
        // event_type_id: _.isNumber(events?.eventData?.event_type) ? events?.eventData?.event_type : events?.eventData?.event_type_id,
        event_type_id: form?.eventForm?.values?.event_type_id,
    };
    if (events?.certificate_selected === "") {
        delete newData.certificate_id;
    }
    delete newData.created_by_initials;
    delete newData.created_by_colour_code;
    delete newData.is_join;
    delete newData.certificate_issued;
    delete newData.start_date;
    delete newData.start_time;
    delete newData.end_time;
    delete newData.end_date;
    delete newData.date_schedule;
    delete newData.event_schedule;
    delete newData.total_hours;
    delete newData.publish;
    delete newData.event_approval;
    delete newData.event_type;
    // let coverPhoto = new File([], 'cover_photo');
    if (isDataURL(newData?.cover_photo)) {
        let image = localStorage.getItem('cover_photo');
        let file = base64ToFile(image, events?.uploadedData[0]?.filename);
        if (data?.cover_photo) {
            newData.cover_photo = file;
            delete newData?.created_by;
            delete newData?.created_by_logo;
            delete newData?.unique_id;
            delete newData?.user_type_id;
            delete newData?.followers;
            delete newData?.like_count;
            delete newData?.share_count;
            delete newData?.owner_details;
            delete newData?.manager_details;
        }
    } else if (isURL(newData?.cover_photo)) {
        delete newData?.cover_photo
        delete newData?.created_by;
        delete newData?.created_by_logo;
        delete newData?.unique_id;
        delete newData?.user_type_id;
        delete newData?.followers;
        delete newData?.like_count;
        delete newData?.share_count
        delete newData?.owner_details;
        delete newData?.manager_details;
    }
    if (newData?.management_type_id === 1) {
        if (newData?.partner_id === null || newData?.partner_id) {
            delete newData.partner_id;
        }
    }
    if (newData?.event_mode_id === 2) {
        delete newData?.transportation_mode_id;
    }
    if (newData?.event_mode_id === null) {
        delete newData.event_mode_id;
    }
    if (newData?.transportation_mode_id === null) {
        delete newData.transportation_mode_id;
    }
    if (newData?.address === null) {
        delete newData.address;
    }
    if (newData?.pin_code === null) {
        delete newData.pin_code;
    }
    if (newData?.district_id === null) {
        delete newData.district_id;
    }
    if (newData?.state_id === null) {
        delete newData.state_id;
    }
    if (newData?.thematic_area_id === null) {
        delete newData.thematic_area_id;
    }
    if (newData?.partner_id === null) {
        delete newData.partner_id;
    }
    let formData = new FormData();
    for (const key in newData) {
        if (Array.isArray(newData[key])) {
            newData[key].forEach((value) => {
                if (key !== "poc") {
                    formData.append(`${key}[]`, value)
                }
            });
        }
        else {
            formData.append(key, newData[key])
        }
    }
    events.newPoc.forEach((item) => {
        formData.append(`poc[][name]`, item.name);
        formData.append(`poc[][phone_number]`, item.phone_number);
        formData.append(`poc[][email]`, item.email);
    });
   
    const eventScheduleDates = events.eventScheduleDates;
    const eventScheduleTimes = events.eventScheduleTimes;
    if (eventScheduleDates?.length > 0) {
        eventScheduleDates?.forEach((timeItem, index) => {
            const event_date = eventScheduleDates[index];
            formData.append(`event_schedule[${index}][event_date]`, event_date);
            formData.append(`event_schedule[${index}][start_time]`, `${event_date} ${eventScheduleTimes[index].start_time}:00`);
            formData.append(`event_schedule[${index}][end_time]`, `${event_date} ${eventScheduleTimes[index].end_time}:00`);
        });
    }
    formData.append('co_owner_id', co_owner_id);
    formData.append('company_id', company_id || company_Id);
    formData.append('user_id', user_id);
    formData.append('created_from_mobile', false);
    // formData.append('event_approval', events.event_Approval === "yes" ? true : false);
    // debugger;
    if (Role_Id === 2 && data.management_type_id === 1 && events?.eventPublish !== "draft") {
        formData.append('event_approval', events.event_Approval === "yes" ? true : false);
    } else if (Role_Id === 1 && data.management_type_id === 1) {
        formData.append('event_approval', false);
    } else if (Role_Id === 1 &&  data.management_type_id === 2 || data.management_type_id === 3) {
        // formData.append('event_approval', true);
        formData.append('event_approval', events?.eventPublish !== "draft" ? true : '');
    } else if (Role_Id === 2 && data.management_type_id === 2 || data.management_type_id === 3) {
        // formData.append('event_approval', events.event_Approval === "yes" ? true : true);
        formData.append('event_approval', events?.eventPublish === "draft" ? '' : true);
    } else {
        formData.append('event_approval', '');
    }
    // debugger;
    // formData.append('admin_approval', events.event_Approval === "yes" ? true : false);
    if (Role_Id === 2 && data.management_type_id === 1) {
        formData.append('admin_approval', events.event_Approval === "yes" ? true : false);
    } else if (Role_Id === 1 && data.management_type_id === 1) {
        formData.append('admin_approval', false);
    } else if (Role_Id === 2 && (data.management_type_id === 2 ||data.management_type_id === '2' || data.management_type_id === 3||data.management_type_id === '3')) {
        formData.append('admin_approval', events.event_Approval === "yes" ? true : false);
    } else if (Role_Id === 1 && data.management_type_id === 2 || data.management_type_id === 3) {
        formData.append('admin_approval', false);
    } else {
        formData.append('admin_approval', '');
    }

    formData.append('publish_comment', form?.commentForm?.values?.comment ? form.commentForm.values.comment : "");
    
    dispatch(fetchAPI(APIUrl, method, formData, '', true)).then(
        response => {
            if (response.success) {
                if (events?.eventPublish === "draft") {
                    dispatch(addEventData(response.data));
                    dispatch(setUnqId(response.data.unique_id))
                    dispatch(setUniqueId(response.data.unique_id))
                    dispatch(setEvent_Status_code(response.data.status_code))
                    dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                } else if (navigate !== '') {
                    dispatch(resetEventData());
                    dispatch(reset('eventForm'));
                    dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                    localStorage.removeItem('cover_photo');
                    navigate("/events/publish-event");
                } else {
                    localStorage.removeItem('cover_photo');
                    dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
    const { master: { district } } = getState();
    if (districtIds?.length > 1 && Array?.isArray(districtIds)) {
        const districtList = districtIds && districtIds.map((id) => {
            const filterList = district?.find((data) => data?.id == id);
            if (filterList) {
                return filterList.name;
            }
        });
        return districtList.toString();
    } else {
        const filterList = district?.find((data) => data?.id == districtIds);
        if (filterList) {
            return filterList.name;
        }
    }
};
export const getLanguageNameByIds = (languageIds) => (dispatch, getState) => {
    const { master: { langauge } } = getState();

    if (languageIds?.length > 1 && Array?.isArray(languageIds)) {
        const languageList = languageIds?.map((id) => {
            const languageObj = langauge?.find((data) => data?.id == id);
            if (languageObj) {
                return languageObj.value;
            }
            return '';
        });
        return languageList.join(', ');
    } else {
        const languageObj = langauge?.find((data) => data?.id == languageIds);
        return languageObj ? languageObj.value : '';
    }
};

export const getTransportationModeNameByIds = (modeIds) => (dispatch, getState) => {
    const { master: { transportationMode } } = getState();

    if (modeIds?.length > 1 && Array?.isArray(modeIds)) {
        const modeList = modeIds?.map((id) => {
            const modeObj = transportationMode?.find((data) => data?.id == id);
            if (modeObj) {
                return modeObj.name;
            }
            return '';
        });
        return modeList.join(', ');
    } else {
        const modeObj = transportationMode?.find((data) => data?.id == modeIds);
        return modeObj ? modeObj.name : '';
    }
};

export const getEventAreaNameByIds = (areaIds) => (dispatch, getState) => {
    const { master: { thematicAreas } } = getState();

    if (areaIds?.length > 1 && Array?.isArray(areaIds)) {
        const areaList = areaIds?.map((id) => {
            const areaObj = thematicAreas?.find((data) => data?.id == id);
            if (areaObj) {
                return areaObj.value;
            }
            return '';
        });
        return areaList.join(', ');
    } else {
        const areaObj = thematicAreas?.find((data) => data?.id == areaIds);
        return areaObj ? areaObj.value : '';
    }
};

export const getBeneficiaryNameByIds = (ids) => (dispatch, getState) => {
    const { master: { eventArea, beneficiaryTypes } } = getState();

    if (ids?.length >= 1 && Array?.isArray(ids)) {
        const beneficairyList = ids?.map((id) => {
            const beneficairyObj = beneficiaryTypes?.find((data) => data?.id == id);
            if (beneficairyObj) {
                return beneficairyObj.value;
            }
            return '';
        });
        return beneficairyList.join(', ');
    } else {
        const beneficairyObj = beneficiaryTypes?.find((data) => data?.id == ids);
        return beneficairyObj ? beneficairyObj.value : '';
    }
};

export const getBrandNameId = (id) => (dispatch, getState) => {
    const { events: { brandList } } = getState();
    const BrandList = brandList?.find((data) => data?.id == id);
    return BrandList?.brand_name;
};

export const getManagementTypeNameId = (id) => (dispatch, getState) => {
    const { master: { managementType } } = getState();
    const managementName = managementType?.find((data) => data?.id == id);
    return managementName?.name;
};


export const submitVolunteers = (values, setVolunteersToAdd) => (dispatch, getState) => {
    const { events: { volunteers, allVolunteers, admins } } = getState();

    const volunteer = { name: values.name, email: values.email }
    //    dispatch(addVolunteers(volunteer))
    setVolunteersToAdd(prev => [...prev, volunteer]);
    dispatch(reset('addVolunteerForm'));
}


export const submitAllVolunteers = (values, handleClose) => (dispatch, getState) => {
    console.log({ values })
    const { events: { volunteers, allVolunteers, addPopupErrorData }, users: { userData } } = getState();
    const userTypeId = userData?.data?.user_type_id

    let dataToSend = {
        user_info: values,
        // userTypeId: userTypeId,
        user_type_id: 8,
    };
    // console.log({dataToSend})
    dispatch(fetchAPI(ADD_VOLUNTEER, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                // dispatch(updateAllVolunteers(response.data))
                dispatch(getAllVolunteers())
                dispatch(setSnackbar({ flag: true, message: 'Volunteers Added successfully', type: "success" }));
                handleClose();
                dispatch(reset('addVolunteerForm'));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                // dispatch(setAddPopupErrorData(response?.data));
                if (response.http_code === 422) {
                    const responseData = response?.data;
                    dispatch(setAddPopupErrorData(responseData));
                } else {
                    dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
                }
            }
        },
    );
    // dispatch(reset('addVolunteerForm'));
}

export const resetNotification = () => (dispatch) => {
    dispatch(fetchAPI(RESET_NOTIFICATION, httpMethod.GET,)).then(
        response => {
            if (response.success) {
                dispatch(getNotificationCount());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}

export const getAllVolunteers = (search, status, offset = 10) => (dispatch) => {
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let dataToSend = {
        name: search || "",
        status: status || '',
        page: convertedPage,
    };
    dispatch(fetchAPI(GET_VOLUNTEERS, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(updateAllVolunteers(response.data.list))
                dispatch(setTotalVolunteersCount(response?.data?.total_volunteers_count));
                dispatch(resetNotification());
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getCertificate = (uniqueId) => (dispatch) => {
    const dataToSend = {
        unique_id: uniqueId
    }
    dispatch(fetchAPI(CERTIFICATE, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setCertificate(response.data))
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const certificatePost = (selectedCertificateId, navigate) => (dispatch) => {
    const dataToSend = {
        id: selectedCertificateId,
    }
    dispatch(fetchAPI(CERTIFICATE_POST, httpMethod.POST, dataToSend)).then(
        response => {
            if (response.success) {
                navigate('/events/dashboard/event-diary/published')
                // dispatch(setCertificate(response.data))
                dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const resendVolunteerEmail = (email, volunteers, selectedEmails) => (dispatch, getState) => {
    const { events: { allVolunteers } } = getState();
    const dataToSend = {
        user_info: volunteers.map((volunteer, index) => {
            // Find the corresponding volunteer from allVolunteers
            const matchedVolunteer = allVolunteers.find((allVolunteer) => allVolunteer.id === volunteer.id);
            return {
                email: matchedVolunteer ? matchedVolunteer.email : '',
                id: volunteer.id,
                // is_registered: matchedVolunteer ? matchedVolunteer.is_registered === 1 ? true : false : '',
            };
        }),
    };

    var formdata = new FormData();
    console.log('Form Data:', formdata);
    dataToSend.user_info.forEach(info => {
        formdata.append("user_info[][id]", info.id);
        // formdata.append("user_info[][is_registered]", info.is_registered);
        formdata.append("user_info[][email]", info.email);
    });

    console.log('User Info:', dataToSend.user_info);

    dispatch(fetchAPI(RESEND_VOLUNTEER_EMAIL, httpMethod.POST, formdata, "", true)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        },
    );
}

export const deleteVolunteer = (volunteers) => (dispatch, getState) => {
    const { users: { userData } } = getState();
    const company_id = userData?.data?.company_id;
    let dataToSend = {
        user_info: volunteers.map(volunteer => ({
            user_id: volunteer.id,
            // is_registered: volunteer.is_registered,
            company_id: company_id,
        })),
    };

    var formdata = new FormData();
    dataToSend.user_info.forEach(info => {
        formdata.append("user_info[][user_id]", info.user_id);
        // formdata.append("user_info[][is_registered]", info.is_registered);
        formdata.append("user_info[][company_id]", info.company_id);
    });

    dispatch(fetchAPI(DELETE_VOLUNTEER, httpMethod.DELETE, formdata, "", true)).then(
        response => {
            if (response.success) {
                dispatch(getAllVolunteers());
                dispatch(setSnackbar({ flag: true, message: COMMON_MSG.RECORD_DELETED, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}


export const setEventFavourite = (isFavorite, uniqueId) => (dispatch, getState) => {
    let dataToPost = {
        isFavorite,
        uniqueId,
    };
    dispatch(fetchAPI(`${EVENT_MARK_FAVOURITE}is_favourite=${isFavorite}&unique_id=${uniqueId}`, httpMethod.PUT)).then(
        response => {
            if (response.success) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
};

export const volunteerAcceptDeny = (email, user_id, company_id, is_accepted) => (dispatch, getState) => {
    // const { events: { allVolunteers } } = getState();
    // const { users: { userData } } = getState();
    // const company_id = userData?.data?.company_id;
    const dataToSend = {
        company_id: company_id,
        email,
        is_accepted,
        id: user_id,
    };
    console.log('User Info:', dataToSend.user_info);
    dispatch(fetchAPI(VOLUNTEER_ACCEPT_DENY, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        },
    );
}
export const volunteerWithdrawInvite = (email, user_id, company_id, withdraw) => (dispatch, getState) => {
    // const { events: { allVolunteers } } = getState();
    // const { users: { userData } } = getState();
    // const company_id = userData?.data?.company_id;
    const dataToSend = {
        company_id: company_id,
        email,
        withdraw,
        id: user_id,
    };
    console.log('User Info:', dataToSend.user_info);
    dispatch(fetchAPI(WITHDRAW_INVITE, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }))
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        },
    );
}
const isURL = (str) => {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}


export const getEventPreview = (gid) => (dispatch, getState) => {
    const { socialMedia: { eventData } } = getState();
    // if (!_.isEmpty(eventData)) return; // stop calling extra api if data is present in event data
    dispatch(fetchAPI(`${SOCIAL_EVENT_PREVIEW}${gid}`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(addEventData(response.data));
                // dispatch(getNotificationCount())
                // dispatch(setUploadedDataForPreview(response.data));
            } else if (response.error) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
};

// export const getEventPreview = (uniqueId) => (dispatch, getState) => {
//     const { events: { eventData } } = getState();
//     if (!_.isEmpty(eventData)) return; // stop calling extra api if data is present in event data
//     dispatch(fetchAPI(`${EVENT_PREVIEW}${uniqueId}`, httpMethod.GET)).then(
//         response => {
//             if (response.success) {
//                 dispatch(setNewEventData(response));
//                 dispatch(getNotificationCount())
//                 dispatch(setUploadedDataForPreview(response.data));
//             } else if (response.error) {
//                 // dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
//             }
//         }
//     );
// };

const setNewEventData = (response) => (dispatch) => {
    dispatch(addEventData(response.data));
    dispatch(setEventListData([]));
    dispatch(setEventInsightData({}));
    const eventData = response.data;
    const eventScheduleDates = eventData?.event_schedule.map(item => item.event_date);
    const eventScheduleTimes = eventData?.event_schedule.map(({ event_date, start_time, end_time, ...rest }) => ({
        start_time: start_time.substring(0, 5),
        end_time: end_time.substring(0, 5)
    }));
    dispatch(updatePOC(eventData?.poc));
    dispatch(setEventScheduleTime(eventScheduleTimes));
    dispatch(setEventScheduleDates(eventScheduleDates));
    dispatch(updateEventInitalValues(response, eventScheduleDates, eventScheduleTimes));
}

const updateEventInitalValues = (response, eventScheduleDates, eventScheduleTimes) => (dispatch) => {
    const dataToSave = {
        event_type: response?.data?.event_type_id || '',
        event_type_id: response?.data?.event_type_id || '',
        cover_photo: response?.data?.cover_photo || '',
        name: response?.data?.name || '',
        description: response?.data?.description || '',
        certificate_id: response?.data?.certificate_id || '',
        thematic_area_id: response?.data?.thematic_area_id || '',
        beneficiary_type: response?.data?.beneficiary_type || '',
        beneficiary_count: response?.data?.beneficiary_count || '',
        tentative_cost: response?.data?.tentative_cost || '',
        volunteers_count: response?.data?.volunteers_count || '',
        volunteer_languages: response?.data?.volunteer_languages || '',
        volunteer_notes: response?.data?.volunteer_notes || '',
        event_mode_id: response?.data?.event_mode_id || '',
        transportation_mode_id: response?.data?.transportation_mode_id || '',
        pin_code: response?.data?.pin_code || '',
        address: response?.data?.address || '',
        district_id: response?.data?.district_id || '',
        state_id: response?.data?.state_id || '',
        management_type_id: response?.data?.management_type_id || '',
        partner_id: response?.data?.partner_id || '',
        online_link: response?.data?.online_link || '',
        start_time: eventScheduleTimes.length > 0 ? eventScheduleTimes[0].start_time : '',
        end_time: eventScheduleTimes.length > 0 ? eventScheduleTimes[0].end_time : '',
        date_schedule: eventScheduleDates || [],
        poc: response?.data?.poc || '',
    }
    dispatch(setEventInitalValues(dataToSave));
}

export const getEventList = (type, offset = 10, pageLimit = PAGE_LIMIT) => (dispatch, getState) => {
    const { events: { primaryTab } } = getState();
    dispatch(setIsLoad(true));
    let APIUrl;
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    if (primaryTab === EVENT_DASHBOARD.PARTNER) {
        APIUrl = `${EVENT_LIST}page=${convertedPage}&event_status=${type}&offset=${pageLimit}&event_type=partner_event`;
    } else {
        APIUrl = `${EVENT_LIST}page=${convertedPage}&event_status=${type}&offset=${pageLimit}&event_type=self_event`;
    }
    // let dataToPost = {
    //     type,
    //     offset,
    //     pageLimit,
    // };
    return dispatch(fetchAPI(APIUrl, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(getInsightData());
                dispatch(setEventListData(response?.data));
                dispatch(setTotalCount(response?.data?.total_event_count));
                dispatch(setIsLoad(false));
                // console.log(response?.data?.list)
                return response.data;
            } else if (response?.error) {
                dispatch(setIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};
export const getEventsList = (offset = 10) => (dispatch, getState) => {
    // debugger;
    // const { events: { eventList } } = getState();
    dispatch(setEventIsLoad(true));
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };

    return dispatch(fetchAPI(SOCIAL_EVENT_LIST, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                dispatch(setEventListData(response?.data?.list))
                dispatch(setTotalCount(response?.data?.total_event_count));
                dispatch(setEventIsLoad(false));
                // console.log(response?.data?.list)
                return response.data;
            } else if (response?.error) {
                dispatch(setEventIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};

export const getMyEventsList = (offset = 10, loading, setLoading, status) => (dispatch, getState) => {
    // debugger;
    const { socialMedia: { myEventList } } = getState();
    loading ? setLoading(true) : dispatch(setEventIsLoad(true));
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    if(status){
        newData = {
            page: convertedPage,
            status
        }
    }

    return dispatch(fetchAPI(SOCIAL_EVENT_LIST, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                !loading ? dispatch(setMyEventListData(response?.data)) : response?.data?.list && dispatch(setMyEventListData([...myEventList, {list: [...myEventList.list, ...response?.data?.list]}]))
                dispatch(setTotalMyCount(response?.data?.total_event_count));
                loading ? setLoading(false) : dispatch(setEventIsLoad(false));
                // console.log(response?.data?.list)
                return response.data;
            } else if (response?.error) {
                dispatch(setEventIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};
export const getSortByList = (offset = 10, loading, setLoading, sort_by, array) => (dispatch, getState) => {
    const { socialMedia: { eventList } } = getState();
    loading ? setLoading(true) : dispatch(setEventIsLoad(true));
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    if(!_.isEmpty(sort_by)){
        newData.sort_by = sort_by;
    }
    if(array && array.length !== 0){
        newData = {
            page: convertedPage,
            sort_by,
            categories: array
        }
    }
    return dispatch(fetchAPI(SOCIAL_EVENT_LIST, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                !loading ? dispatch(setEventListData(response?.data?.list)) : response?.data?.list && dispatch(setEventListData([...eventList, ...response?.data?.list]));
                // dispatch(setTotalCount(response?.data?.total_event_count));
                loading ? setLoading(false) : dispatch(setEventIsLoad(false));
                // console.log(response?.data?.list)
                return response.data;
            } else if (response?.error) {
                loading ? setLoading(false) : dispatch(setEventIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};
export const getCategoryEventsList = (offset = 10, loading, setLoading, array) => (dispatch, getState) => {
    const { socialMedia: { eventList } } = getState();
    console.log(array)
    loading ? setLoading(true) : dispatch(setEventIsLoad(true));
    // Logic to convert multiples of 10 to 1, 2, 3, ...
    let convertedPage = Math.ceil(offset / 10);
    let newData = {
        page: convertedPage,
    };
    if(array && array.length !== 0){
        newData.categories = array;
    }
    return dispatch(fetchAPI(SOCIAL_EVENT_LIST, httpMethod.GET, newData)).then(
        response => {
            if (response.success) {
                !loading ? dispatch(setEventListData(response?.data?.list)) : response?.data?.list &&dispatch(setEventListData([...eventList, ...response?.data?.list]));
                dispatch(setTotalCount(response?.data?.total_event_count));
                loading ? setLoading(false) : dispatch(setEventIsLoad(false));
                // console.log(response?.data?.list)
                return response.data;
            } else if (response?.error) {
                loading ? setLoading(false) : dispatch(setEventIsLoad(false));
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        }
    );
};


export const getLeaderboardList = () => (dispatch, getState) => {
    dispatch(fetchAPI(EVENT_LEADERBOARD, httpMethod.GET)).then(
        (response) => {
            if (response.success) {
                if (response?.data?.leaderboard) {
                    dispatch(setLeaderboardList(response?.data?.leaderboard))
                }
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
            } else if (response.error) {
                dispatch(
                    setSnackbar({
                        flag: true,
                        message: response.message,
                        type: "error",
                    })
                );
            }
        }
    );
};


export const getInsightData = (uniqueId) => (dispatch, getState) => {
    let APIUrl;
    if (uniqueId) {
        APIUrl = `${INSIGHT}?unique_id=${uniqueId}`
    } else {
        APIUrl = INSIGHT;
    }
    return dispatch(fetchAPI(APIUrl, httpMethod.GET)).then(
        response => {
            dispatch(setIsLoad(false));
            if (response.success) {
                dispatch(setEventInsightData(response?.data));
                // console.log(response?.data)
                return response.data;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
}

export const getMyEventList = (type, page, event_status, offset = 0, pageLimit = PAGE_LIMIT) => (dispatch, getState) => {
    const { users: { userData } } = getState();
    const individual = userData?.data?.role_key;
    let dataToPost = {
        // type,
        // offset,
        individual: individual ? true : false,
        page,
        event_status: type,
        // pageLimit,
    };
    return dispatch(fetchAPI(`${EVENT_LIST}page=1&event_status=${type}&offset=${offset}`, httpMethod.GET)).then(
        response => {
            if (response.success) {
                // dispatch(setEventInsightData(response?.data?.insight));
                dispatch(setEventListData(response?.data));
                dispatch(setTotalCount(response?.data?.total_event_count));
                // console.log(response?.data, "response")
                return response.data;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
};

export const getUserDetail = () => (dispatch, getState) => {
    // const { users: { userData } } = getState();
    // const user_id = userData?.data?.id;
    // let dataToPost = {
    //     user_id: user_id,
    // };
    return dispatch(fetchAPI(USER_DETAIL, httpMethod.GET,)).then(
        response => {
            if (response) {
                dispatch(setTileData(response.data.list));
                // console.log(response?.data, "response")
                // dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
                return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const getJoinEvent = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    let dataToPost = {
        gid,
    };
    return dispatch(fetchAPI(JOIN_EVENT, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(addEventData({...eventData, is_join: 'Requested'}));
                // dispatch(getMyEventList(secondaryTab));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const DownloadCertificate = (gid) => (dispatch, getState) => {
    let dataToSend = {
        gid,
    };
    dispatch(fetchAPI(DOWNLOAD_CERTIFICATE, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                dispatch(setDownloadCertificate(response.data))
                // console.log('Certificate URL:', response.data.url);
                const link = document.createElement('a');
                link.href = response.data.certificate;
                link.download = 'Download_Certificate.pdf';
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.click();
                // return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error.errorMsg, type: "error" }));
            }
        },
    );
}
export const getDownloadCertificate = (gid) => (dispatch, getState) => {
    let dataToSend = {
        gid,
    };
    dispatch(fetchAPI(DOWNLOAD_CERTIFICATE, httpMethod.GET, dataToSend)).then(
        response => {
            if (response.success) {
                // dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                dispatch(setDownloadCertificate(response.data?.certificate_image))
                // console.log('Certificate URL:', response.data.url);
                // const link = document.createElement('a');
                // link.href = response.data.url;
                // link.download = 'Download_Certificate.pdf';
                // link.target = '_blank';
                // link.rel = 'noopener noreferrer';
                // link.click();
                // return response;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        },
    );
}
export const getOptOut = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    let dataToPost = {
        gid,
    };
    return dispatch(fetchAPI(OPT_OUT_EVENT, httpMethod.PUT, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")    
                dispatch(addEventData({...eventData, is_join: 'Join'}));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        }
    );
};

export const getLiked = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    let dataToPost = {
        gid,
    };
    return dispatch(fetchAPI(EVENT_LIKE, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addEventData({...eventData, self_like: true, like_count: eventData?.like_count + 1}));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const deleteLiked = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    let dataToPost = {
        gid,
    };
    return dispatch(fetchAPI(EVENT_DELETE_LIKE, httpMethod.DELETE, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addEventData({...eventData, self_like: false, like_count: eventData?.like_count - 1}));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const setBookmark = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    let dataToPost = {
        bookmarkable_id: gid,
        bookmarkable_type: "SocialEvent", 
    };
    return dispatch(fetchAPI(EVENT_BOOKMARK, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")    
                dispatch(addEventData({...eventData, bookrmark: {gid: response?.data?.gid, is_bookrmark: true}}));
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const deleteBookmark = (gid) => (dispatch, getState) => {
    const {socialMedia: {eventData}} = getState();
    return dispatch(fetchAPI(`${EVENT_DELETE_BOOKMARK}/${gid}`, httpMethod.DELETE)).then(
        response => {
            if (response.success) {
                // console.log(response, "response")
                dispatch(addEventData({...eventData, bookrmark: {gid: null, is_bookrmark: false}}));
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.error?.errorMsg, type: "error" }));
            }
        }
    );
};

export const sendRatings = (gid, values, onClose) => (dispatch, getState) => {
    let dataToPost = {
        gid,
        ...values
    }
    return dispatch(fetchAPI(SHARE_RATING, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
                dispatch(DownloadCertificate(gid));
                onClose();
                dispatch(setSnackbar({ flag: true, message: response?.data?.message, type: "success" }));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "error" }));
            }
        }
    );
};

export const setEventPreview = (uniqueId, isEdit = false, navigate = '') => (dispatch, getState) => {
    const { events: { eventList } } = getState();
    if (eventList.length > 0) {
        const eventData = eventList.find((item) => item.uniqueId === uniqueId);
        if (!_.isEmpty(eventData)) {
            dispatch(addEventData(eventData));
            dispatch(setUploadedDataForPreview(eventData));
        }
    } else {
        dispatch(getEventPreview(uniqueId));
    }
}

export const setUploadedDataForPreview = (eventData) => (dispatch, getState) => {
    let uploadArr = [];
    if (eventData?.cover_photo) {
        let uploadedData = {
            filename: eventData?.cover_photo,
            key: 'cover_photo'
        }
        uploadArr.push(uploadedData);
    }
    if (eventData?.picture_one) {
        let uploadedData = {
            filename: eventData?.picture_one,
            key: 'picture_one'
        }
        uploadArr.push(uploadedData);
    }
    if (eventData?.picture_two) {
        let uploadedData = {
            filename: eventData?.picture_two,
            key: 'picture_two'
        }
        uploadArr.push(uploadedData);
    }
    if (eventData?.picture_three) {
        let uploadedData = {
            filename: eventData?.picture_three,
            key: 'picture_three'
        }
        uploadArr.push(uploadedData);
    }
    dispatch(updateEventUploadedData(uploadArr));
}


export const getBrandList = () => (dispatch, getState) => {
    const { events: { brandList } } = getState();
    if (!_.isEmpty(brandList)) return;
    dispatch(fetchAPI(BRAND_LIST, httpMethod.GET)).then((response) => {
        if (response.success) {
            const brandList = response?.data?.list
            dispatch(setBrandListData(brandList));
        } else if (response.error) {
            dispatch(
                setSnackbar({
                    flag: true,
                    message: response.message,
                    type: "error",
                })
            );
        }
    });
};


export const submitCancelEvent = (values, handleClose, statusCode) => (dispatch, getState) => {
    // console.log("Action")
    // let dataToPost = {
    //     statusCode : STATUS_CODE.CANCEL.CODE,
    const cancel_reason = values.description;
    const uniqueId = dispatch(getEventUniqueId());
    // }    
    dispatch(fetchAPI(`${CANCEL_EVENT}unique_id=${uniqueId}&cancel_reason=${cancel_reason}&status_code=${statusCode}`, httpMethod.PUT)).then(
        response => {
            if (response) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                dispatch(reset('cancelEventForm'));
                handleClose();
                dispatch(getEventsList());
                // dispatch(getEventList(EVENT_DASHBOARD.PUBLISHED));
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
}
export const submitCancelEventFeedback = (values, handleClose) => (dispatch, getState) => {
    // console.log("Action")
    const cancel_reason = values.description;
    const uniqueId = dispatch(getEventUniqueId());
    let dataToPost = {
        unique_id: uniqueId,
        feedback: cancel_reason,
        // statusCode : STATUS_CODE.CANCEL.CODE,
    }
    dispatch(fetchAPI(CANCEL_EVENT_FEEDBACK, httpMethod.POST, dataToPost)).then(
        response => {
            if (response) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                dispatch(reset('feedbackEventForm'));
                handleClose();
                dispatch(getEventsList());
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
}


export const submitOutcomeData = (values, navigate) => (dispatch, getState) => {
    const { events: { uniqueId } } = getState();
    // console.log(values.outcomeForm , uniqueId)
}

export const setOutcomeData = () => (dispatch, getState) => {
    const { events: { uniqueId, eventList } } = getState();
    if (eventList.length > 0) {
        const eventData = eventList.find((item) => item.uniqueId === uniqueId);
        // console.log('eventData', eventData);
        if (!_.isEmpty(eventData)) {
            dispatch(addOutcomeData(eventData));
        }
    } else {
        // console.log('error')
    }
}

export const resetEventData = () => (dispatch) => {
    dispatch(addEventData({}));
    dispatch(setUniqueId(null));
    dispatch(updateEventUploadedData([]));
    dispatch(resetAllForms());
    dispatch(setPrivateEvent(false));
    dispatch(setEventInitalValues({}));
    dispatch(setEventScheduleTime({}));
    dispatch(setEventScheduleDates({}));
    dispatch(updatePOC({}));
    dispatch(setSelectedEventMode(""));
    dispatch(setSelectedEventType(""));
    dispatch(setCertificate_selected(""));
}


export const getUploadedDocURL = (uploadInfo) => (dispatch, getState) => {
    const { events: { eventData } } = getState();
    if (!eventData) {
        return ''; // or any default value as per your requirement
    }
    // console.log(eventData.find(name => name === uploadInfo.FIELD_NAME), "uploadData");
    let searchObject = localStorage.getItem(uploadInfo?.FIELD_NAME);
    if (searchObject) {
        return searchObject;
    } else {
        return eventData[uploadInfo?.FIELD_NAME];
    }
    // const searchObject = uploadedData && uploadedData.find((data) => data.key === uploadInfo.FIELD_NAME);
    // if (searchObject) {
    //     let firlURL = `${FILE_HOST}${searchObject.filename}`;
    //     if (searchObject.filename) {
    //         return firlURL;
    //     }
    // }
}

export const uploadCsv = (uploadedFile) => (dispatch, getState) => {
    console.log('uploaded_File', uploadedFile);

    let dataToSend = {
        file: uploadedFile,
    };
    console.log('dataToSend:', dataToSend);
    var formdata = new FormData();

    for (let key in dataToSend) {
        if (dataToSend.hasOwnProperty(key)) {
            formdata.append(key, dataToSend[key]);
        }
    }

    console.log('Form Data:', formdata);
    dispatch(setSnackbar({ flag: true, message: 'Uploading in progress', type: "success" }));

    return dispatch(fetchAPI(UPLOAD_CSV, httpMethod.POST, formdata, "", true)).then(
        (response) => {
            if (response.success) {
                dispatch(getAllVolunteers());
                setTimeout(() => {
                    dispatch(setSnackbar({ flag: true, message: response.message, type: "success" }));
                }, 4000);
                // return response.data;
            } else if (response.error) {
                dispatch(getAllVolunteers());
                setTimeout(() => {
                    dispatch(
                        setSnackbar({
                            flag: true,
                            message: response.message,
                            type: "error",
                        })
                    );
                }, 4000);
            }
        }
    );
};

export const doEventActions = (action, uniqueId) => (dispatch, getState) => {
    let dataToPost = {
        action,
        uniqueId,
    };
    return dispatch(fetchAPI(SOCIAL_OUTCOME, httpMethod.POST, dataToPost)).then(
        response => {
            if (response.success) {
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
};

export const getVolunteerCount = () => (dispatch) => {
    return dispatch(fetchAPI(VOLUNTEER_COUNT, httpMethod.GET)).then(
        response => {
            if (response.success) {
                dispatch(setVolunteersCount(response.data));
                return response.data;
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.message, type: "error" }));
            }
        }
    );
}

export const handleNewPoc = (values, editFlag, index, handleToggleFields) => (dispatch, getState) => {
    dispatch(reset('newPocForm'));
    dispatch(setNewPocFlag(true));
    let { events: { newPoc } } = getState();
    if (editFlag) {
        let newArr = [...newPoc];
        newArr[index] = values;
        dispatch(updatePOC(newArr));
    } else {
        dispatch(addPOC(values));
    }
    dispatch(reset('newPocForm'));
    dispatch(setNewPocFlag(false));
    handleToggleFields();
}

export const deleteNewPoc = (deleteIndex) => (dispatch, getState) => {
    let { events: { newPoc } } = getState();
    const newArr = newPoc.filter((data, index) => (
        index != deleteIndex
    ));
    dispatch(updatePOC(newArr));
}
// export const ApprovalRequest = (values, onClose, navigate) => (dispatch, getState) => {

//     let { events } = getState();
//     if(events.eventData.partner !== true){
//         dataToSend.admin_approval= events.approval_status_code === 1017 ? true: false,
//     };
//     let dataToSend = {
//         comment: values?.comment,
//         unique_id: events.eventData?.unique_id || events?.uniqueId,
//         status_code: events.approval_status_code,
//     };
   
//     return dispatch(fetchAPI(`${APPROVAL_REQUEST}`, httpMethod.PUT, dataToSend)).then(
//         response => {
//             if (response.success) {
//                 dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
//                 // dispatch(getEventPreview(events.eventData.unique_id))
//                 dispatch(reset('ApproveDeclineForm'))
//                 if (events.approval_status_code === 1015) {
//                     onClose();
//                     // if (dashboard) {
//                     navigate(`/events/listing`);
//                     // }
//                 }
//             } else if (response.error) {
//                 dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
//             }
//         }
//     );
// };
export const ApprovalRequest = (values, onClose, navigate) => (dispatch, getState) => {
    let { events } = getState();

    let dataToSend = {
        comment: values?.comment,
        unique_id: events.eventData?.unique_id || events?.uniqueId,
        status_code: events.approval_status_code,
    };

    // Check if partner is not true, then set admin_approval property
    if (events.eventData.partner !== true) {
        dataToSend.admin_approval = events.approval_status_code === 1017 ? true : false;
    }

    return dispatch(fetchAPI(`${APPROVAL_REQUEST}`, httpMethod.PUT, dataToSend)).then(
        response => {
            if (response.success) {
                dispatch(setSnackbar({ flag: true, message: response?.message, type: "success" }));
                dispatch(reset('ApproveDeclineForm'));
                if (events.approval_status_code === 1015) {
                    onClose();
                    navigate(`/events/listing`);
                }
            } else if (response.error) {
                dispatch(setSnackbar({ flag: true, message: response.error, type: "error" }));
            }
        }
    );
};
